/* eslint-disable */
import React from "react"
import {graphql} from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import Offers from '../../components/common/Offers'
import CarbonContainer from "../../components/pages/CarbonPage";

class CarbonPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}


  render() {
    const { data } = this.props
    let urls = {
      en: data.defaultPage.urls.en,
      fr: data.defaultPage.urls.fr
    }

    return (
      <Layout
        meta={data.defaultPage.meta}
        urls={urls}
        locale='fr'
      >
        <Header
          urls={urls}
          locale='fr'
        />
        <CarbonContainer locale='fr' />
        <Footer
          cn
          extraRules={
            '<sup>†</sup>Les produits de papier Royale<sup>®</sup> sont fabriqués par Les Produits de consommation Irving Limitée et Les Produits de consommation Irving, Inc., des sociétés affiliées à J.D. Irving, Limited. J.D. Irving, Limited et ses sociétés affiliées ont planté plus de 1 milliard d’arbres depuis 1957.' +
            '<br/><br/>*Chaque année, les forêts sous la gestion de J.D. Irving, Limited retirent de l’atmosphère une quantité plus grande de carbone que celle émise durant le cycle de vie des produits de papier Royale<sup>®</sup>.<br><br>**Vérification par un tiers'
          }
        />
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  defaultPage(guid: { eq: "3ce73b99-42c8-4bf9-bd90-c0b6a48eaeae" }, locale: {eq:"fr"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default CarbonPage
